import { useLazyQuery, useQuery } from "@apollo/client";
import { navigate, RouteComponentProps, WindowLocation } from "@reach/router";
import { Avatar, Card, List } from "antd";
import { BuldingIcon, CheckCircleIcon, ChevronRightIcon, UserIcon } from "app/icons";
import { StyledCardBody, StyledCardTitle } from "app/main/campaigns/styles";
import Loading from "app/ui/loading";
import { ALL_ORGANIZATIONS } from "graphql/organizations/queries";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useOrganization from "app/hooks/useOrganization";
import { Organization, Permission, User } from "__generated__/GraphQLTypes";
import { USER_BY_ID } from "graphql/users/queries";
import { StyledModalContent } from "../styles";
import { authManager } from "../utils";

const StyledOrganizationCard = styled(Card)`
  padding: 20px !important;
  min-height: 148px;

  .ant-card-head,
  .ant-card-head-title {
    padding: 0;
  }

  .ant-card-body {
    padding: 0;
    font-size: 12px;
  }

  .checked {
    position: absolute;
    top: 18px;
    right: 18px;
  }
`;

const SelectOrganization: React.FC<RouteComponentProps> = ({ location }): JSX.Element => {
  const { state } = location as WindowLocation<User>;

  const [getOrganizations, organizationsData] = useLazyQuery(ALL_ORGANIZATIONS);

  const [disable, setDisable] = useState(false);
  const [choosenOrganization, setChoosenOrganization] = useState("");
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [, handleSelecting] = useOrganization();

  useEffect(() => {
    getOrganizations().then(() => {
      setOrganizations(organizationsData?.data?.organizations.nodes);
    });
  }, [organizationsData.data]);

  const goToDashboard = () => {
    navigate("/campaigns");
  };

  const organizationSelecting = (selected: any) => {
    handleSelecting(selected);
    setChoosenOrganization(selected._id);
    setDisable(true);
  };

  return (
    <StyledModalContent>
      <div className="contentHeader">
        <div className="contentTitle">👋 Bienvenue {`${state?.firstname} ${state?.lastname}`} !</div>
        <span className="contentSubtitle">
          Selectionnez une organisation pour accéder à <span className="discovery">Discovery</span>
        </span>
      </div>

      <div className="contentBody">
        {organizationsData.loading ? (
          <Loading />
        ) : (
          <div style={{ marginTop: 20, padding: 10 }}>
            <List
              grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 5 }}
              dataSource={organizations}
              renderItem={(item) => (
                <List.Item onClick={() => organizationSelecting(item)}>
                  <StyledOrganizationCard
                    title={
                      <StyledCardTitle>
                        <Avatar size={55} src={item.logo} />
                        <div className="cardTitle">{item?.name}</div>
                      </StyledCardTitle>
                    }
                    extra={
                      choosenOrganization === item._id && (
                        <div className="checked">
                          <CheckCircleIcon color="#165ecc" />
                        </div>
                      )
                    }
                  >
                    <StyledCardBody>
                      <div className="cardBodyRow">
                        <BuldingIcon />
                        <div>{item?.stats?.totalAgencies} Agence(s)</div>
                      </div>
                      <div className="cardBodyRow">
                        <UserIcon />
                        <div>{item?.stats?.totalReceivers} Receveurs(s) </div>
                      </div>
                    </StyledCardBody>
                  </StyledOrganizationCard>
                </List.Item>
              )}
            />
          </div>
        )}
      </div>
      <div className="nextButton">
        <button
          disabled={!disable}
          style={{ backgroundColor: disable ? "#165ecc" : "gray" }}
          type="button"
          onClick={goToDashboard}
        >
          <span>Continuer</span>
          <span>
            <ChevronRightIcon height={10} color="white" />
          </span>
        </button>
      </div>
    </StyledModalContent>
  );
};

export default SelectOrganization;
