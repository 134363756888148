import { DRAWER_WITH } from "app/constants";
import styled from "styled-components";
import { Header } from "antd/lib/layout/layout";

export const StyledHeader = styled(Header)`
  align-items: center;
  background-color: var(--app-bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  right: 0;
  width: calc(100% - ${DRAWER_WITH}px);
  .headerItem {
    margin-right: 15px;
    margin-top: 10px;
    button {
      border: none;
      background: transparent;
    }
    .organization {
      margin-bottom: 10px;
      span {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
  .avatarBlock {
    margin-top: -35px;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;
