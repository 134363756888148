import { Layout } from "antd";
import { APP_BAR_HEIGHT, DRAWER_WITH } from "app/constants";
import styled from "styled-components";

const { Sider } = Layout;

export const Wapper = styled(Sider)`
  .ant-menu-root {
    border: 0;
    height: calc(100% - ${APP_BAR_HEIGHT}px);
    overflow: hidden;
    overflow-y: scroll;
    position: fixed;
    width: ${DRAWER_WITH}px;
  }

  .ant-menu-item {
    align-items: center;
    color: var(--neutral-900);
    display: flex;
    height: 48px;
    margin-bottom: 4px !important;
    padding: 0 25px;

    .ant-menu-title-content {
      margin-left: 12px;
    }
  }

  .ant-menu-submenu {
    .ant-menu-title-content {
      margin-left: 12px;
    }
  }

  .ant-menu-inline .ant-menu-item::after {
    border-width: 0;
  }

  .ant-menu:not(.ant-menu-horizontal) {
    .ant-menu-item-selected,
    .ant-menu-item-active {
      background-color: var(--primary-100);
      a {
        color: var(--primary-color);
      }
      path {
        fill: var(--primary-color);
      }
    }
  }
`;
