import { LayoutProps } from "..";
import { Wrapper } from "./styles";

export const Content = ({ children }: LayoutProps): JSX.Element => <Wrapper>{children}</Wrapper>;

Content.defaultProps = {
  children: undefined
};

export default Content;
export * from "./Header";
export * from "./HeaderBox";
