import { useEffect, useState } from "react";

const useOrganization = () => {
  const [selectedOrganization, setSelectedOrganization] = useState(JSON.parse(localStorage.getItem("organization")));
  useEffect(() => {
    handleSelecting(JSON.parse(localStorage.getItem("organization")));
  }, [selectedOrganization?._id]);

  const handleSelecting = (selectedItem: any) => {
    localStorage.setItem("organization", JSON.stringify(selectedItem));
    setSelectedOrganization(selectedItem);
  };

  return [selectedOrganization, handleSelecting];
};

export default useOrganization;
