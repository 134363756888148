import { useMutation } from "@apollo/client";
import { navigate } from "@reach/router";
import { message } from "antd";
import { ACCOUNT_VERIFICATION } from "graphql/auth/mutations";

import ActivationForm from "../components/ActivationForm";
import { StyledFormWrapper } from "../styles";
import { ActivationFormValues, ActivationProps } from "../types";

const Activation = ({ token }: ActivationProps): JSX.Element => {
  const [verifyAccount, { loading }] = useMutation(ACCOUNT_VERIFICATION);

  const handleSubmit = (formValues: ActivationFormValues) => {
    verifyAccount({
      variables: {
        input: {
          token,
          password: formValues.password
        }
      }
    })
      .then(() => {
        message.success([<p>Compte activé avec succès</p>, <p>Veuillez vous connecter pour acceder à votre espace</p>]);
        navigate("/auth/login");
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  return (
    <StyledFormWrapper>
      <div className="header-text">Activation de compte</div>
      <ActivationForm submitted={loading} onSubmit={handleSubmit} />
    </StyledFormWrapper>
  );
};

Activation.defaultProps = {
  token: null
};

export default Activation;
