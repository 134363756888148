import { Link, navigate, RouteComponentProps } from "@reach/router";
import React from "react";
import { Form, Input, Button, Space, message } from "antd";
import { useMutation } from "@apollo/client";
import { AppRoutes } from "app/routing";
import { RESET_PASSWORD_REQUEST } from "graphql/auth/mutations";
import { StyledFormWrapper } from "../styles";
import { ILoginFormValues } from "../types";

const Forgot: React.FC<RouteComponentProps> = (): JSX.Element => {
  const [sendRequest, { loading }] = useMutation(RESET_PASSWORD_REQUEST);

  const handleSubmit = (formValues: Pick<ILoginFormValues, "email">) => {
    sendRequest({
      variables: {
        input: {
          ...formValues
        }
      }
    })
      .then(() => {
        message.success("Mail de réinitialisation envoyer");
        navigate(AppRoutes.LOGIN);
      })
      .catch(() => {
        message.error("Email incorrect");
      });
  };

  return (
    <StyledFormWrapper>
      <Form name="login-form" onFinish={handleSubmit}>
        <div className="header-text">Mot de passe oublié ?</div>
        <div className="subheader-text">
          Saisissez votre e-mail ci-dessous et nous vous enverrons un lien de réinitialisation.
        </div>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, type: "email", message: "Entrer votre email!" }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit" block>
              Envoyer
            </Button>
          </Form.Item>
          <div className="forgot-password">
            <Link to={AppRoutes.LOGIN}>Retourner à la page de connexion</Link>
          </div>
        </Space>
      </Form>
    </StyledFormWrapper>
  );
};

export default Forgot;
