import React from "react";
import { Layout as AntLayout } from "antd";
import SideBar from "../side-bar";
import AppBar from "../app-bar";

export interface LayoutProps {
  children?: React.ReactNode;
}

const MainLayout = ({ children }: LayoutProps): JSX.Element => (
  <AntLayout className="app-layout">
    <SideBar />
    <AntLayout>
      <AppBar />
      <AntLayout.Content className="main">{children}</AntLayout.Content>
    </AntLayout>
  </AntLayout>
);

MainLayout.defaultProps = {
  children: null
};

export default MainLayout;
export * from "./Content";
export * from "./Auth";
