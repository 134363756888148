import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #fff;
  height: 64px;
  padding: 14px;
  text-align: center;
  width: 100%;

  img {
    width: 122px;
  }
`;
