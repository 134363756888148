import { AuthResponse } from "__generated__/GraphQLTypes";

export const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY;

const STORAGE = window.localStorage;

export const authManager = {
  storeToken(token: AuthResponse): void {
    if (token) {
      STORAGE.setItem(TOKEN_KEY, JSON.stringify(token));
    }
  },
  readToken(): AuthResponse {
    return JSON.parse(STORAGE.getItem(TOKEN_KEY));
  },
  removeToken(): void {
    STORAGE.removeItem(TOKEN_KEY);
  }
};
