import { Spin } from "antd";
import styled from "styled-components";

const StyledLoading = styled.div`
  align-items: center;
  display: flex;
  height: 50vh;
  justify-content: center;
`;

function Loading() {
  return (
    <StyledLoading>
      <Spin />
    </StyledLoading>
  );
}

export default Loading;
