import styled from "styled-components";

export const StyledCardTitle = styled.div`
  display: inline-flex;
  align-items: center;
  .cardTitle {
    word-wrap: break-word;
    width: 150px;
    margin-left: 5px;
    font-size: 16px;
  }
  .cardSubtitle {
    word-wrap: break-word;
    width: 160px;
    margin-left: 5px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
  }
`;

export const StyledCardBody = styled.div`
  .cardBodyRow {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
    div {
      margin-left: 5px;
    }
    .addressBullet {
      width: 200px;
    }
  }
`;
