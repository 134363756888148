import { gql } from "@apollo/client";

export const AUTH = gql`
  mutation auth($input: AuthInput!) {
    auth(input: $input) {
      accessToken
      refreshToken
      user {
        _id
        firstname
        lastname
        role {
          _id
          name
          permissions {
            _id
            name
            module {
              _id
              name
            }
          }
        }
      }
    }
  }
`;

export const AUTH_CLIENT = gql`
  mutation authDCClient($input: AuthInput!) {
    authDCClient(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const ACCOUNT_VERIFICATION = gql`
  mutation verifyAccount($input: AccountVerificationInput!) {
    verifyAccount(input: $input) {
      accessToken
      refreshToken
    }
  }
`;

export const RESET_PASSWORD_REQUEST = gql`
  mutation resetPasswordRequest($input: ResetPasswordRequestInput!) {
    resetPasswordRequest(input: $input)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;
