export const AppRoutes = {
  HOME: "/",
  HISTORY: "/history",
  CAMPAIGNS_HISTORY: "/history/campaigns",
  TRANSMISSIONS_HISTORY: "/history/transmissions",
  CAMPAIGNS: "/campaigns",
  TRANSMISSIONS: "/transmissions",
  TRANSMISSIONS_DETAILS: "/transmissions/:id",
  TRANSMISSIONS_RECEIVERS_IMPORT: "/transmissions/:id/receivers/import",
  AGENCIES: "/agencies",
  RECEIVERS: "/receivers",
  IMPORT: "receivers/import",
  PAPSERS: "/papsers",
  USERS: "/users",
  ORGANIZATIONS: "/organizations",
  ORGANIZATION_DETAILS: "/organizations/:id",
  PROFILE: "/profile",
  SETTINGS: "/settings",
  LOGIN: "/auth/login",
  LOGOUT: "/auth/logout",
  RESET: "/auth/reset",
  FORGOT: "/auth/forgot",
  ROLES: "/roles",
  ROLES_DETAILS: "/roles/:id"
} as const;

export default AppRoutes;
