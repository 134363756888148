import styled from "styled-components";

export const StyledButton = styled.div`
  height: 20px;
  cursor: pointer;
  align-self: center;
  justify-content: center;
  background-color: transparent;
  text-align: center;
  display: flex;
`;

export const StyledSelect = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;
