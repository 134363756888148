import { Button, Form, Input, Space } from "antd";
import { ActivationFormProps } from "../types";

export const ActivationForm = ({ submitted, onSubmit }: ActivationFormProps): JSX.Element => (
  <Form name="login-form" initialValues={{ remember: false }} onFinish={onSubmit}>
    <Space direction="vertical" style={{ width: "100%" }}>
      <Form.Item
        name="password"
        label="Mot de passe"
        rules={[{ required: true, message: "Veuillez saisir votre mot de passe!" }]}
      >
        <Input type="password" minLength={4} />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirmez votre mot de passe"
        dependencies={["password"]}
        rules={[
          { required: true, message: "Veuillez confirmer votre mot de passe!" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Les deux mots de passe que vous avez saisis ne correspondent pas!"));
            }
          })
        ]}
      >
        <Input type="password" />
      </Form.Item>

      <Form.Item>
        <Button disabled={submitted} loading={submitted} type="primary" htmlType="submit" block>
          Confirmer
        </Button>
      </Form.Item>
    </Space>
  </Form>
);

export default ActivationForm;
