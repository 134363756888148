import React, { Suspense } from "react";
import MainLayout from "app/ui/Layout";
import { Router } from "@reach/router";
import { Skeleton } from "antd";
import { AppRoutes } from "app/routing";

const Agencies = React.lazy(() => import("./agencies"));
const Dashboard = React.lazy(() => import("./dashboard"));
const Campaigns = React.lazy(() => import("./campaigns"));
const Users = React.lazy(() => import("./users"));
const Receivers = React.lazy(() => import("./receivers"));
const Transmissions = React.lazy(() => import("./transmissions"));
const Organizations = React.lazy(() => import("./organizations"));
const Papsers = React.lazy(() => import("./papsers"));
const Profile = React.lazy(() => import("./profile"));
const Roles = React.lazy(() => import("./roles"));
const RoleDetails = React.lazy(() => import("./roles/Details"));
const CampaignHistory = React.lazy(() => import("./history/campaigns"));
const TransmissionHistory = React.lazy(() => import("./history/transmissions"));

const Main = (): JSX.Element => (
  <MainLayout>
    <Suspense fallback={<Skeleton />}>
      <Router>
        <Dashboard path={AppRoutes.HOME} />
        <Profile path={AppRoutes.PROFILE} />
        <Campaigns path={`${AppRoutes.CAMPAIGNS}/*`} />
        <Transmissions path={`${AppRoutes.TRANSMISSIONS}/*`} />
        <Receivers path={`${AppRoutes.RECEIVERS}/*`} />
        <Agencies path={`${AppRoutes.AGENCIES}/*`} />
        <Users path={AppRoutes.USERS} />
        <Organizations path={`${AppRoutes.ORGANIZATIONS}/*`} />
        <Papsers path={AppRoutes.PAPSERS} />
        <Roles path={AppRoutes.ROLES} />
        <RoleDetails path={AppRoutes.ROLES_DETAILS} />
        <CampaignHistory path={AppRoutes.CAMPAIGNS_HISTORY} />
        <TransmissionHistory path={AppRoutes.TRANSMISSIONS_HISTORY} />
      </Router>
    </Suspense>
  </MainLayout>
);

export default Main;
