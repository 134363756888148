import { Avatar as AntHeader } from "antd";

const Avatar = (): JSX.Element => (
  <AntHeader
    style={{
      backgroundColor: "#00406F",
      verticalAlign: "middle"
    }}
    size="large"
  >
    VS
  </AntHeader>
);

export default Avatar;
