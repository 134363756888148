import { useCallback, useState } from "react";

/**
 *
 * @param initialState boolean
 * @returns a boolean stateful value, and a function to update it
 */
function useToggle(initialState = false): [boolean, () => void] {
  const [state, setState] = useState(initialState);

  const toggle = useCallback(() => setState((current) => !current), []);

  return [state, toggle];
}

export default useToggle;
