import { Link, useMatch } from "@reach/router";
import { Menu } from "antd";
import useMenuGroup from "app/config/menu";
import { DRAWER_WITH, LOGO_PATH } from "app/constants";
import { AbitlityContext } from "app/shared/@pbac";
import Can from "app/shared/@pbac/Can";
import { hasAbilities } from "app/shared/@pbac/utils";
import { IMenu } from "app/types";
import clsx from "clsx";
import { useContext, useMemo } from "react";

import Logo from "../Logo";
import { Wapper } from "./styles";

const SideBar = (): JSX.Element => {
  const match = useMatch(`/${window.location.pathname.split("/")[1] || ""}/*`);
  const { abilities: userAbilities } = useContext(AbitlityContext);

  const { MENU } = useMenuGroup();

  const renderMenuItem = (menu: IMenu, isSubMenu: boolean) => {
    if (hasAbilities(userAbilities, menu.abilities) && !isSubMenu) {
      return (
        <Menu.Item
          icon={<menu.icon />}
          key={menu.key}
          className={clsx({ "ant-menu-item-selected": match?.uri === menu.url })}
        >
          <Link to={menu.url}>{menu.label}</Link>
        </Menu.Item>
      );
    }
    if (hasAbilities(userAbilities, menu.abilities) && isSubMenu) {
      return (
        <Menu.SubMenu key={menu.key} title={menu.label} icon={<menu.icon />}>
          {menu?.children?.map((submenu) => (
            <Menu.Item key={submenu.key} className={clsx({ "ant-menu-item-selected": match?.uri === submenu.url })}>
              <Link to={submenu.url}>{submenu.label}</Link>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      );
    }
    return <></>;
  };

  const menuRendering = useMemo(
    () =>
      MENU.map(
        (menuGroup) =>
          !menuGroup.hide && (
            <Can abilities={menuGroup?.abilities}>
              <Menu.ItemGroup key={menuGroup.key}>
                {menuGroup.menus.map((menu) => !menu.hide && renderMenuItem(menu, !!menu?.children?.length))}
              </Menu.ItemGroup>
            </Can>
          )
      ),
    [MENU, match]
  );

  return (
    <Wapper width={DRAWER_WITH}>
      <Logo path={LOGO_PATH} />
      <Menu mode="inline">{menuRendering}</Menu>
    </Wapper>
  );
};

export default SideBar;
