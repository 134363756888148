import React from "react";
import { LayoutProps } from "..";
import { ContentHeaderBoxWrapper } from "./styles";

export interface ContentHeaderBoxProps extends LayoutProps {
  justify: "start" | "end" | "space-between";
}

export const ContentHeaderBox = ({ children, justify }: ContentHeaderBoxProps): JSX.Element => (
  <ContentHeaderBoxWrapper justify={justify}>
    {React.Children.map(children, (item) => (
      <div className="box-item">{item}</div>
    ))}
  </ContentHeaderBoxWrapper>
);

export default ContentHeaderBox;
