import React, { createContext } from "react";

export interface AbitlityProviderState {
  abilities: string[];
}

export interface AbitlityProviderProps {
  abilities: string[];
  children: React.ReactNode;
}

export const AbitlityContext = createContext<AbitlityProviderState>({
  abilities: []
});

AbitlityContext.displayName = "AbitlityContext";

export const AbitlityProvider = ({ abilities, children }: AbitlityProviderProps) => (
  <AbitlityContext.Provider value={{ abilities }}>{children}</AbitlityContext.Provider>
);

export default AbitlityContext;
