import i18n from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"]
};

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(I18nextBrowserLanguageDetector)
  .init({
    backend: {
      loadPath: "/assets/i18n/{{ns}}/{{lng}}.json"
    },
    fallbackLng: "en",
    debug: false,
    detection: DETECTION_OPTIONS,
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false
    }
  });
export default i18n;
