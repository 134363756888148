import { LOGO_PATH } from "app/constants";
import { LayoutProps } from ".";
import Logo from "../Logo";
import { AuthCard, AuthLayoutWrapper } from "./styles";

export const AuthLayout = ({ children }: LayoutProps): JSX.Element => (
  <AuthLayoutWrapper>
    <AuthCard>
      <Logo path={LOGO_PATH} />
      <div className="content">{children}</div>
    </AuthCard>
  </AuthLayoutWrapper>
);

AuthLayout.defaultProps = {
  children: null
};

export default AuthLayout;
