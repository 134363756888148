import { useQuery } from "@apollo/client";
import { ALL_ORGANIZATIONS_FOR_SELECTIONS } from "graphql/organizations/queries";
import React from "react";
import { Organization } from "__generated__/GraphQLTypes";

export interface OraganzationProviderState {
  organizations: Organization[];
  selectedOrganization: Organization;
  setOrganizations: (organizations: Organization[]) => void;

  setSelectedOrganization: (organization: Organization) => void;
}

export interface OrganizationProps {
  selectedOrganization?: Organization;
}

export interface OrganizationProviderProps {
  children: React.ReactNode;
}
export const OrganizationContext = React.createContext<OraganzationProviderState>({
  organizations: [],
  selectedOrganization: null,
  setSelectedOrganization(selectedOrganization) {
    throw new Error("OrganizationContext not yet initialized.");
  },
  setOrganizations(organizations) {
    throw new Error("OrganizationContext not yet initialized.");
  }
});

OrganizationContext.displayName = "OrganizationContext";

export const OragnizationProvider = ({ children }: OrganizationProviderProps) => {
  const { data } = useQuery(ALL_ORGANIZATIONS_FOR_SELECTIONS);
  const [organizations, setOrganizations] = React.useState<Organization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = React.useState<Organization>(null);

  React.useEffect(() => {
    setOrganizations(data?.organizations.nodes);
  }, [data]);
  const contextValue = React.useMemo(
    () => ({ organizations, setOrganizations, setSelectedOrganization, selectedOrganization }),
    [organizations, selectedOrganization, setOrganizations, setSelectedOrganization]
  );

  return <OrganizationContext.Provider value={contextValue}>{children}</OrganizationContext.Provider>;
};

export const OrganizationConsumer = OrganizationContext.Consumer;

export const useOrganization = () => React.useContext(OrganizationContext);
export function withOrganization<T>(Component: React.ComponentType<T>) {
  return function WithOrganization(props: T) {
    return RenderChild(Component, props);
  };
}

function RenderChild<T>(Component: React.ComponentType<T>, props: any) {
  const { selectedOrganization } = useOrganization();
  const selected = React.useMemo(() => selectedOrganization, [selectedOrganization]);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component selectedOrganization={selected} {...(props as T)} />;
}

export default OrganizationContext;
