import { Link, navigate, RouteComponentProps, useLocation } from "@reach/router";
import { Breadcrumb, Select, Spin } from "antd";
import { authManager } from "app/auth/utils";
import { AppPlatform } from "app/config";
import i18next from "app/config/i18next";
import { useOrganization } from "app/contexts/organiztion-context";
import { ChevronDownIcon } from "app/icons";
import { breadCrumbAtom } from "app/store/breadcrumb-atom";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import Avatar from "../avatar";
import MenuGroup from "../menu-group";
import { StyledHeader } from "./styles";

const { Option } = Select;

const actions: { _id: string; name: string }[] = [
  { _id: "0", name: "Profile" },
  { _id: "1", name: "Déconnexion" }
];

const PATH_LABEL = {
  "type-of-points": "Type de points",
  users: "Utilisateurs"
};

const AppBar: React.FC<RouteComponentProps> = (): JSX.Element => {
  const retrieveOrganization = localStorage.getItem("organization");
  const [lang, setLang] = useState<string>(localStorage.getItem("i18nextLng"));
  const { organizations, setSelectedOrganization, selectedOrganization } = useOrganization();
  // const location = useLocation();
  const [breadcrumbs] = useRecoilState(breadCrumbAtom);

  useEffect(() => {
    if (retrieveOrganization) {
      setSelectedOrganization(JSON.parse(retrieveOrganization));
    } else {
      navigate("/selectOrganization");
    }
  }, []);

  const handleAction = React.useCallback(
    (selected: any) => {
      setSelectedOrganization(selected);
      localStorage.setItem("organization", JSON.stringify(selected));
    },
    [selectedOrganization?._id]
  );

  const handleLogout = (action: any) => {
    if (action.name === "Profile") {
      navigate(`/profile`);
    }
    if (action.name === "Déconnexion") {
      authManager.removeToken();
      navigate("/auth/login");
    }
  };

  const handleChang = (value) => {
    setLang(value);
    i18next.changeLanguage(value);
  };

  return (
    <StyledHeader>
      <div>
        <Breadcrumb>
          {/* {location.pathname
            .split("/")
            .filter((path) => path)
            .map((path) => (
              <Breadcrumb.Item key={path}>
                {PATH_LABEL[path] || _.capitalize(path).replaceAll("-", " ")}
              </Breadcrumb.Item>
            ))} */}
          {breadcrumbs.map((breadcrumb) =>
            breadcrumbs.length > 1 && breadcrumb.path ? (
              <Link to={breadcrumb?.path}>
                <Breadcrumb.Item>{breadcrumb.label}</Breadcrumb.Item>
              </Link>
            ) : (
              <Breadcrumb.Item>{breadcrumb.label}</Breadcrumb.Item>
            )
          )}
        </Breadcrumb>
      </div>

      <div className="right">
        {process.env.REACT_APP_PLATFORM !== AppPlatform.Client && (
          <div className="headerItem">
            <div className="organization">
              {selectedOrganization && !selectedOrganization ? (
                <Spin size="small" />
              ) : (
                <MenuGroup
                  onClick={handleAction}
                  items={organizations || []}
                  text={selectedOrganization?.name}
                  element={<ChevronDownIcon />}
                />
              )}
            </div>
          </div>
        )}
        <div>
          <Select value={lang} bordered={false} onChange={handleChang}>
            <Option value="fr" key="fr">
              FR
            </Option>
            <Option value="en" key="en">
              EN
            </Option>
          </Select>
        </div>
        <div className="avatarBlock">
          <MenuGroup onClick={handleLogout} items={actions} element={<Avatar />} trigger={["click"]} />
        </div>
      </div>
    </StyledHeader>
  );
};

export default AppBar;
