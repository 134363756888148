import { Link } from "@reach/router";
import React from "react";
import { Wrapper } from "./styles";

const Logo = ({ path }: { path: string }): JSX.Element => (
  <Wrapper>
    <Link to="/campaigns">
      <img src={path} alt="Discovery Logo" />
    </Link>
  </Wrapper>
);

export default React.memo(Logo);
