const ABILITIES = {
  admin: {
    can_view: "view:admin"
  },
  papser: {
    can_view: "view:papser"
  },
  history: {
    can_view: "view:historique"
  },
  receiver: {
    can_create: "create:receveur",
    can_view: "view:receveur"
  },
  campaign: {
    can_create: "create:campagne"
  },
  transmission: {
    can_create: "create:transmission",
    can_view: "view:transmission"
  },
  agency: {
    can_create: "create:agence",
    can_view: "view:agence"
  },
  organization: {
    can_create: "create:organisation",
    can_view: "view:organisation"
  },
  role: {
    can_create: "create:role",
    can_view: "view:role"
  },
  user: {
    can_create: "create:utilisateur",
    can_view: "view:utilisateur"
  }
};

export default ABILITIES;
