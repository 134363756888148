import styled from "styled-components";

export const AuthLayoutWrapper = styled.div`
  align-items: center;
  background-image: url("/auth-screen-bg.png");
  background-position: 30% 40%;
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
`;

export const AuthCard = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1.2px 3.5px 0 rgba(0, 0, 0, 0.2), 0 2.4px 2.4px 0 rgba(0, 0, 0, 0.12), 0 0 2.4px 0 rgba(0, 0, 0, 0.14);
  padding: 40px 59px;
  width: 420px;

  img {
    width: 149px;
  }

  .content {
    margin-top: 10px;
  }
`;
