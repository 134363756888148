import { LocationProvider, Router } from "@reach/router";
import AuthRoutes, { PrivateRoute as PrivateRoutes } from "./auth";
import SelectOrganization from "./auth/containers/select-organization";
import { OragnizationProvider } from "./contexts/organiztion-context";
import Main from "./main";
import GlobalStyle from "./styles";

function App(): JSX.Element {
  return (
    <LocationProvider>
      <GlobalStyle />
      <OragnizationProvider>
        <Router>
          <AuthRoutes path="/auth/*" />
          <PrivateRoutes as={Main} path="/*" />
          <SelectOrganization path="selectOrganization" />
        </Router>
      </OragnizationProvider>
    </LocationProvider>
  );
}

export default App;
