import { Link } from "@reach/router";
import { Button, Form, Input, Space } from "antd";
import { AppRoutes } from "app/routing";
import { LoginFormProps } from "../types";

export const LoginForm = ({ submitted, onLogin }: LoginFormProps): JSX.Element => (
  <Form name="login-form" initialValues={{ remember: true }} onFinish={onLogin}>
    <Space direction="vertical" style={{ width: "100%" }}>
      <Form.Item name="email" label="Email" rules={[{ required: true, type: "email", message: "Entrer votre email!" }]}>
        <Input type="email" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Mot de passe"
        rules={[{ required: true, message: "Entrer votre mot de passe!" }]}
      >
        <Input type="password" />
      </Form.Item>

      <Form.Item>
        <Button disabled={submitted} loading={submitted} type="primary" htmlType="submit" block>
          Se connecter
        </Button>
      </Form.Item>
      <div className="forgot-password">
        <Link to={AppRoutes.FORGOT}>Mot de passe oublié ?</Link>
      </div>
    </Space>
  </Form>
);

export default LoginForm;
