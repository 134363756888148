import React from "react";
import { Menu, Dropdown } from "antd";
import { StyledButton, StyledSelect } from "./styles";

export interface MenuGroupProps {
  items: any[];
  element: any;
  text?: string;
  trigger?: any;
  onClick: (key: string) => void;
}

const MenuGroup = ({ items, onClick, element, trigger, text }: MenuGroupProps): React.ReactElement => {
  const handleClick = (selectedItem: string) => {
    onClick(selectedItem);
  };

  const menu = React.useMemo(
    () => (
      <Menu>
        {items.map((item) => (
          <Menu.Item key={item?._id} onClick={() => handleClick(item)}>
            <StyledSelect>{item.name}</StyledSelect>
          </Menu.Item>
        ))}
      </Menu>
    ),
    [items]
  );

  return (
    <StyledSelect type="button">
      <Dropdown overlay={menu} trigger={trigger} placement="bottomCenter">
        <StyledButton>
          <span> {text} </span>
          <div>{element}</div>
        </StyledButton>
      </Dropdown>
    </StyledSelect>
  );
};

export default MenuGroup;
