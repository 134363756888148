import { createGlobalStyle } from "styled-components";
import "antd/dist/antd.css";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Proxima";
    src: url("/fonts/proxima/ProximaNova-Light.woff2") format("woff2"),
      url("/fonts/proxima/ProximaNova-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: auto;
    /* Read next point */
    unicode-range: U+000-5FF;
  }

  @font-face {
    font-family: "Proxima";
    src: url("/fonts/proxima/ProximaNova-Regular.woff") format("woff"),
      url("/fonts/proxima/ProximaNova-Regular.woff2") format("woff2"),
      url("/fonts/proxima/ProximaNova-Regular.ttf") format("truetype");
    font-weight: normal;
    font-display: auto;
    /* Read next point */
    unicode-range: U+000-5FF;
  }

  @font-face {
    font-family: "Proxima";
    src: url("/fonts/proxima/ProximaNova-Semibold.woff") format("woff"),
      url("/fonts/proxima/ProximaNova-Semibold.woff2") format("woff2"),
      url("/fonts/proxima/ProximaNova-Semibold.ttf") format("truetype");
    font-weight: 500;
    font-display: auto;
    /* Read next point */
    unicode-range: U+000-5FF;
  }

  :root {
    --primary-100: #eaf1fb;
    --primary-200: #6fa1ec;
    --primary-300: #2f73da;
    --primary-400: #165ecc;
    --primary-500: #034ab3;

    --neutral-100: #f8f9fb;
    --neutral-200: #eef1f6;
    --neutral-300: #e4e8ee;
    --neutral-400: #cdd3dd;
    --neutral-500: #a8b1bd;
    --neutral-600: #6a7381;
    --neutral-700: #49505a;
    --neutral-800: #1f2329;
    --neutral-900: #121417;
    --neutral-white: #fff;

    --transparent-neutral-100: rgba(247, 249, 252, 0.55);
    --transparent-neutral: rgba(247, 249, 252, 0.55);
    --transparent-400: rgba(203, 211, 223, 0.55);
    --transparent-scrim: rgba(0, 0, 0, 0.25);
    --transparent-scrim-darker: rgba(0, 0, 0, 0.75);

    --success-100: #ddfded;
    --success-300: #09a57f;
    --success-400: #078364;
    --success-200: #0ac295;
    --success-500: #027357;

    --error-100: #fcd2cf;
    --error-200: #f45532;
    --error-400: #c61a0b;
    --error-300: #df320c;
    --error-500: #ae0a0a;

    --warning-100: #fef7b9;
    --warning-200: #ffda6c;
    --warning-300: #ffb400;
    --warning-500: #c33e01;
    --warning-400: #e07c02;

    --primary-color: var(--primary-400);
    --text-color: var(--primary-400);
    --app-bg-color: var(--neutral-100);
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-x
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(231, 226, 226);
    border-radius: 30px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 30px;
  }

  body {
    font-family: Proxima, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
          "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }

  .app-layout {
    height: 100vh;
    width: 100%;
  }

  .main {
    padding: 0 45px;
    background-color: var(--app-bg-color);
    margin-top: 64px;
    overflow: scroll;
  }

  .ant-input {
    border-radius: 4px;
    height: 48px;
  }

  .ant-form-item {
    position: relative;
  }

  .ant-form-item-label {
    background-color: #fff;
    height: auto;
    left: 12px;
    padding: 0 4px;
    position: absolute;
    top: -20%;
    width: auto;
    z-index: 1;

    > label {
      height: auto;
      line-height: normal;

      ::after {
        content: none;
      }
    }

  }

  .ant-select-selector {
    border-radius: 4px!important;
    height: 48px!important;
  }

  .ant-select-selection-item {
    line-height: 44px!important;
  }

  .ant-btn {
    align-items: center;
    border-radius: 4px;
    color: var(--primary-400);
    display: flex;
    font-size: 16px;
    font-weight: bold;
    gap: 8px;
    height: 44px;
    justify-content: center;

    :hover {
      border-color: var(--primary-400);
      color: var(--primary-400);

      &.ant-btn-primary {
        background-color: var(--primary-400);
        border-color: var(--primary-400);
        color: #fff;
      }
    }

    &.ant-btn-primary {
      background-color: var(--primary-300);
      border-color: var(--primary-300);
      color: #fff;
    }
  }

  .ant-drawer {
    .ant-drawer-title {
      color: #000;
      font-size: 20px;
      font-weight: bold;
    }

    .ant-drawer-close {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 16px;

      svg {
        background-color: #edf1f7;
        border-radius: 3px;
        color: #19212c;
        height: 22px;
        padding: 4px;
        width: 22px;
      }
    }

    .ant-drawer-footer {
      padding: 16px 24px;
      border-top: 0;
    }
  }

  .ant-table {
    .ant-table-thead > tr > th {
      background-color: #fff;

      :before {
        content: inherit!important;
      }
    }

    .ant-table-row {
      cursor: pointer;

      :hover {
        background-color: var(--primary-100);
        td {
          background-color: var(--primary-100)!important;

        }
      }
    }
  }

  .filter-popover {
    .ant-popover-title {
      font-size: 16px;
      font-weight: bold;
      padding: 16px 24px;
    }
    .ant-popover-inner-content {
      padding: 0;
    }
  }

  .ant-collapse-item {
    margin-bottom: 24px;
    overflow: hidden;
    background: #ffffff;
    background-color: #ffffff;
    border: 0px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width:99%;
    margin-left:10px
  }

  .ant-collapse {
    background-color: white
  }

  .ant-collapse-header {
    font-size: 16px;
    font-weight: bold
  }

  .ant-checkbox-group-custom {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .ant-checkbox {
    margin-top: 15px
  }
  .ant-drawer-header {
    background-color: aliceblue;
  }

  .ant-drawer-body {
    flex-grow: 1;
    padding: 0px;
    overflow: auto;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }

  .ant-picker {
    border-radius:5px;
  }

  .ant-picker-input {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 278px;
    height: 40px;
  }

  .ant-table-footer {
    text-align: end;
}

.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  border: 0px;
  :hover {
    cursor: pointer;
    box-shadow: #165ecc 0px 1px 4px;
  }
}

.ant-card-head {
  min-height: 38px;
  margin-bottom: -1px;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 0px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  height: 75px;
}

.ant-card-body {
  padding: 10px;
}

.ant-card-head-title {
  display: inline-block;
  flex: 1 1;
  padding: 10px 0;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}

.ant-avatar {
  background-color: #e4e8ee;
}

.ant-avatar-image {
  background: transparent;
  border: 2px solid #f9f9f9;
}

.ant-dropdown {
  border: 1px solid #f9f9f9;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
}

.ant-dropdown-menu {
  position: relative;
  margin: 0;
  max-height: 150px;
  overflow: auto;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}

.ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}

.p-5 {
  padding: 5px
}

.ant-breadcrumb {
  a {
    color: var(--primary-color);
    .ant-breadcrumb-link {
      font-size: 14px;
    }
  }
  .ant-breadcrumb-link {
    font-size: 16px;
    font-weight: bold;
  }
}

`;

export default GlobalStyle;
