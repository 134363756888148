import { useMutation } from "@apollo/client";
import { navigate } from "@reach/router";
import { message } from "antd";
import { RESET_PASSWORD } from "graphql/auth/mutations";
import ActivationForm from "../components/ActivationForm";
import { StyledFormWrapper } from "../styles";
import { ActivationFormValues, ActivationProps } from "../types";

const Reset = ({ token }: ActivationProps): JSX.Element => {
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);

  const handleSubmit = (formValues: ActivationFormValues) => {
    resetPassword({
      variables: {
        input: {
          token,
          password: formValues.password
        }
      }
    })
      .then(() => {
        message.success([
          <p>Mot de passe réinitialisé avec succès</p>,
          <p>Veuillez vous connecter pour acceder à votre espace</p>
        ]);
        navigate("/auth/login");
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  return (
    <StyledFormWrapper>
      <div className="header-text">Réinitialisation de mot de passe</div>
      <ActivationForm submitted={loading} onSubmit={handleSubmit} />
    </StyledFormWrapper>
  );
};

Reset.defaultProps = {
  token: null
};

export default Reset;
