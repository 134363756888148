import { PagingType } from "app/types";

export const DRAWER_WITH = 240;
export const APP_BAR_HEIGHT = 64;
export const LOGO_PATH = "/logo.png";
export const ROW_PER_PAGE_OPTIONS = [10, 20, 50];
export const TABLE_HEIGHT = "calc(100vh - 450px)";
export const DEFAULT_PAGING: PagingType = {
  pagingType: "forward",
  rows: ROW_PER_PAGE_OPTIONS[0]
};
export const GOOGLE_MAPS_API_KEY = "AIzaSyDCR2ycw5W4neIDEI7h3xnBtoDBk92h394";

export const IMPORT_ROWS_LIMIT = 3000;

export const DEFAULT_RECEIVERS_FILTER = {
  status: null,
  driver: null
};

export const SEN_EAU_COLUMNS_MATCH = {
  idContrat: "ref_contrat",
  idPDI: "ref_pdi",
  idRappeler: "ref_rappeler",
  name: "nom_client",
  address: "adresse_full",
  phone: "numero_telephone"
};

export const DISCOVERY_HEADERS = [
  {
    key: "idContrat",
    label: "Identifiant contrat du client",
    eg: "(ref_contrat)"
  },
  {
    key: "idPDI",
    label: "Identifiant client PDI",
    eg: "(ref_pdi)"
  },
  {
    key: "idRappeler",
    label: "Identifiant client à rappeler",
    eg: "(ref_rappeler)"
  },
  {
    key: "name",
    label: "Nom client",
    eg: "(nom_client)"
  },
  {
    key: "address",
    label: "Adresse client",
    eg: "(adresse_full)"
  },
  {
    key: "phone",
    label: "Cellulaire client",
    eg: "(numero_telephone)"
  }
];

// eslint-disable-next-line no-shadow
export enum ListFilterType {
  Campaign = "CAMPAIGN",
  Transmission = "TRANSMISSION",
  Receiver = "RECEIVER"
}
