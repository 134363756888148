import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { authManager } from "app/auth/utils";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_END_POINT,
  credentials: "same-origin"
});

const authLink = setContext((_, { headers }) => {
  const token = authManager.readToken()?.accessToken;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null
    }
  };
});

const appLink = from([authLink, httpLink]);

const client = new ApolloClient({
  link: appLink,
  cache: new InMemoryCache()
});

export default client;
