import { useMutation } from "@apollo/client";
import { navigate, RouteComponentProps } from "@reach/router";
import { message } from "antd";
import { AppPlatform } from "app/config";
import { AUTH, AUTH_CLIENT } from "graphql/auth/mutations";
import React from "react";
import LoginForm from "../components/LoginForm";
import { StyledFormWrapper } from "../styles";
import { ILoginFormValues } from "../types";
import { authManager } from "../utils";

const Login: React.FC<RouteComponentProps> = (): JSX.Element => {
  const [auth, { loading }] = useMutation(AUTH);
  const [authClient, { loading: loadingClient }] = useMutation(AUTH_CLIENT);

  const handleLogin = (formValues: ILoginFormValues) => {
    if (process.env.REACT_APP_PLATFORM === AppPlatform.Client) {
      authClient({
        variables: {
          input: {
            ...formValues
          }
        }
      })
        .then((result) => {
          authManager.storeToken(result.data.auth);
          message.success("Connexion réussie");
          navigate("/");
        })
        .catch(() => {
          message.error("Email ou mot de passe incorrect");
        });
    } else {
      auth({
        variables: {
          input: {
            ...formValues
          }
        }
      })
        .then((result) => {
          authManager.storeToken(result.data.auth);
          message.success("Connexion réussie");

          navigate("/selectOrganization", { replace: true, state: result.data.auth?.user });
        })
        .catch(() => {
          message.error("Email ou mot de passe incorrect");
        });
    }
  };

  return (
    <StyledFormWrapper>
      {/* <LoginWithGoogleButton onClick={onGoogleLogin} /> */}
      {/* <Divider plain>ou</Divider> */}
      <div className="header-text">Connexion</div>
      <LoginForm submitted={loading || loadingClient} onLogin={handleLogin} />
    </StyledFormWrapper>
  );
};

export default Login;
