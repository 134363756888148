import React, { useContext } from "react";
import AbitlityContext from "./AbitlityContext";
import { hasAbilities } from "./utils";

interface CanProps {
  abilities: string[];
  children: React.ReactNode;
}

const Can = ({ children, abilities }: CanProps): JSX.Element => {
  const { abilities: userAbilities } = useContext(AbitlityContext);
  const ablited = hasAbilities(userAbilities, abilities);

  return <>{ablited ? children : <></>}</>;
};

export default Can;
