import styled from "styled-components";

export const StyledFormWrapper = styled.div`
  .header-text {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .subheader-text {
    font-size: 14px;
    margin-bottom: 25px;
    text-align: center;
  }
  .google-button {
    box-shadow: 0 2px 8px 0 rgba(106, 115, 129, 0.12);
    color: var(--neutral-900);
    margin-bottom: 15px;
  }
  .forgot-password {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const StyledModalContent = styled.div`
  width: 80vw;
  height: 80vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  .contentHeader {
    text-align: center;
    margin-bottom: 15px;
  }
  .contentTitle {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .contentSubtitle {
    font-size: 18px;
    color: gray;
  }
  .discovery {
    font-size: 20px;
    fontweight: bold;
    color: #165ecc;
  }
  .contentBody {
    margin-top: 5%;
    margin-bottom: 5%;
    max-height: 70%;
    overflow: auto;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .nextButton {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    button {
      color: white;
      font-size: 16px;
      width: 120px;
      height: 40px;
      border: 0px;
      border-radius: 5px;
      cursor: pointer;
      align-items: center;
    }
  }
`;
