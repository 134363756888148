import { Layout } from "antd";
import styled from "styled-components";
import { ContentHeaderBoxProps } from "./HeaderBox";

const boxJustify = {
  start: "flex-start",
  end: "flex-end"
};

export const Wrapper = styled(Layout.Content)`
  background-color: var(--neutral-white);
  border: solid 1.4px var(--neutral-300);
  border-radius: 5.6px;
  height: calc(100vh - 192.8px);
  margin: 17px 0 32px 0;
  overflow-y: scroll;
  padding: 0 24px;
`;

export const ContentHeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 32px 0;
`;

export const ContentHeaderBoxWrapper = styled.div<ContentHeaderBoxProps>`
  align-items: center;
  display: flex;
  flex: auto;
  justify-content: ${(props) => boxJustify[props.justify]};

  .box-item {
    margin-right: 8px;
  }
`;
