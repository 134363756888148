import { LayoutProps } from "..";
import { ContentHeaderWrapper } from "./styles";

export const ContentHeader = ({ children }: LayoutProps): JSX.Element => (
  <ContentHeaderWrapper>{children}</ContentHeaderWrapper>
);

ContentHeader.defaultProps = {
  children: undefined
};

export default ContentHeader;
