import Loading from "app/ui/loading";
import dotenv from "dotenv";
import React, { Suspense } from "react";
import { ApolloProvider, useMutation } from "@apollo/client";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import App from "./app";
import apolloClient from "./app/config/apollo";
import "./app/config/i18next";
import reportWebVitals from "./reportWebVitals";

dotenv.config();
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <RecoilRoot>
        <ApolloProvider client={apolloClient}>
          <App />
        </ApolloProvider>
      </RecoilRoot>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
